import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { mapAnimations, getBrowserLanguage } from '../components/utils'

import Layout from '../components/layout'
import Section from '../components/section/section'
import SectionNav from '../components/navigation/sectionNavigation'
import HelmetWrapper from '../components/helmet/helmetWrapper'

const IndexPage = ({
  data,
  location
}) => {
  const {
    contentfulLocale,
    allContentfulLocale,
    allContentfulMetaHeader,
    allContentfulMetaFooter,
    contentfulPageLandingpage
  } = data

  let localeHeader = null
  let localeFooter = null
  let sectionNav = []
  let maxSections = 0

  if (contentfulPageLandingpage.fragments) {
    maxSections = contentfulPageLandingpage.fragments.length
  }

  const langProps = {
    lang: allContentfulLocale.edges,
    activeLang: contentfulPageLandingpage.node_locale,
    defaultLang: contentfulLocale.code,
    isHomepage: true
  }

  const browserLang = getBrowserLanguage(langProps)

  allContentfulMetaHeader.edges.forEach(metaHeader => {
    if (metaHeader.node.node_locale === langProps.activeLang) {
      localeHeader = metaHeader.node
    }
  })

  allContentfulMetaFooter.edges.forEach(metaFooter => {
    if (metaFooter.node.node_locale === langProps.activeLang) {
      localeFooter = metaFooter.node
    }
  })

  if (contentfulPageLandingpage.pagenavOption) {
    contentfulPageLandingpage.fragments.forEach(fragment => {
      if (fragment.pageNavigation){
        sectionNav.push({
          name: fragment.name,
          id: fragment.id
        })
      }
    })
  }

  useEffect(() => {
    const animatedEl = document.querySelectorAll('.js-gs-wrapper')

    if (animatedEl){
      window.history.scrollRestoration = 'manual'
      window.onload = () => {
        if (window.scrollTo) window.scrollTo(0,0);
      }

      mapAnimations()
    }
  }, [])

  if (browserLang && window.history.length <= 2) {
    navigate(
      `/${browserLang}`,
      { replace: false }
    )

    return null
  }

  return (
    <Layout header={localeHeader} lang={langProps} location={location} footer={localeFooter}>

      { contentfulPageLandingpage.seoJson &&
        <HelmetWrapper
          content={contentfulPageLandingpage.seoJson}
          activeLang={langProps.defaultLang}
        />
      }

      { contentfulPageLandingpage.fragments && contentfulPageLandingpage.fragments.map((section, index) =>
        <Section key={section.id} section={section} id={index} max={maxSections}/>
      )}

      { contentfulPageLandingpage.pagenavOption &&
        <SectionNav items={sectionNav} />
      }

    </Layout>
  )
}

export default IndexPage

export const indexData = graphql`
  query HomepageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulLocale (default: {eq: true}){
      code
      name
    }
    allContentfulLocale {
      totalCount
      edges {
        node {
          code
          name
          default
        }
      }
    }
    allContentfulMetaHeader(filter: {headerActive: {eq: "Yes"}}) {
      edges {
        node {
          ...metaHeaderFields
          navigation {
            __typename
            ... on ContentfulMetaLink {
              ...metaLinkFields
            }
            ... on ContentfulPageLandingpage {
              ...pageLandingpageFields
            }
          }
          contactFieldMobile {
            __typename
            ... on ContentfulMetaLink {
              ...metaLinkFields
            }
          }
        }
      }
    }
    contentfulPageLandingpage(homepageOption: { eq: "Yes" }) {
      ...pageLandingpageFields
      fragments {
        __typename
        ... on ContentfulFragmentSection {
          ...fragmentSectionFields
          fragments {
            __typename
            ... on ContentfulFragmentHeadline {
              ...fragmentHeadlineFields
            }
            ... on ContentfulFragmentRichText {
              ...fragmentRichTextFields
            }
            ... on ContentfulFragmentMedia {
              ...fragmentMediaFields
            }
            ... on ContentfulFragmentHtml {
              htmlCode {
                htmlCode
              }
            }
          }
        }
      }
    }
    allContentfulMetaFooter(filter: {footerActive: {eq: "Yes"}}) {
      edges {
        node {
          id
          node_locale
          leftSection {
            __typename
            ... on ContentfulPageLandingpage {
              ...pageLandingpageFields
            }
            ... on ContentfulMetaLink {
              ...metaLinkFields
            }
          }
          centerSection {
            __typename
            ... on ContentfulPageLandingpage {
              ...pageLandingpageFields
            }
            ... on ContentfulMetaLink {
              ...metaLinkFields
            }
          }
          rightSection {
            __typename
            ... on ContentfulPageLandingpage {
              ...pageLandingpageFields
            }
            ... on ContentfulMetaLink {
              ...metaLinkFields
            }
          }
        }
      }
    }
  }
`
